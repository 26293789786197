// src/contexts/LearningContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  writeBatch,
  arrayUnion,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../firebase';

const LearningContext = createContext();
export const useLearning = () => useContext(LearningContext);

export const LearningProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);
  const [nextWorkbook, setNextWorkbook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const saveTestResult = async (lessonID, testResult) => {
    try {
      if (!lessonID || typeof lessonID !== 'string') {
        throw new Error('Invalid lessonID provided. It must be a string.');
      }
      if (!testResult) throw new Error('Invalid test result provided.');
  
      // Split lessonID into moduleID and lessonID
      const [moduleID, contentID] = lessonID.split('/');
      if (!moduleID || !contentID) {
        throw new Error('Invalid lessonID format. Expected "moduleID/lessonID".');
      }
  
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'lessons',
        contentID,
        'interactions',
        user.uid
      );
  
      const batch = writeBatch(db);
      batch.set(interactionRef, testResult, { merge: true });
      await batch.commit();
  
      console.log('Test result saved successfully:', testResult);
    } catch (err) {
      console.error('Error saving test result:', err.message || err);
      throw err; // Ensure the error propagates
    }
  };
  
  
  // ---------------------------------------------
// 2. FETCH TEST RESULTS FUNCTION
// ---------------------------------------------
const fetchTestResults = async (moduleID, lessonID) => {
  try {
    if (!user) throw new Error('User is not logged in.');
    if (!moduleID || !lessonID) throw new Error('Invalid moduleID or lessonID provided.');

    const interactionRef = doc(
      db,
      'courses',
      user.courseID,
      'modules',
      moduleID,
      'lessons',
      lessonID,
      'interactions',
      user.uid
    );

    console.log('Fetching test results from:', interactionRef.path); // Debug the path
    const interactionDoc = await getDoc(interactionRef);
    if (interactionDoc.exists()) {
      return {
        lessonID,
        userInteractions: interactionDoc.data(),
      };
    } else {
      console.warn(`No interactions found for lesson ${lessonID}`);
      return {
        lessonID,
        userInteractions: {},
      };
    }
  } catch (err) {
    console.error('Error fetching test results:', err.message || err);
    return {
      lessonID,
      userInteractions: {},
      error: 'Failed to fetch test results.',
    };
  }
};

  // --------------------------------------------------
  // 3. STATE FOR USER INTERACTIONS (e.g. quiz answers)
  // --------------------------------------------------
  const [userInteractions, setUserInteractions] = useState({});
  const [currentLessonOrWorkbookID, setCurrentLessonOrWorkbookID] = useState(null);

  // ----------------------------------------
  // 4. FETCH USER & COURSE DATA ONCE LOADED
  // ----------------------------------------
  const fetchUserAndCourseData = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    setError(null);

    try {
      // 4a. Fetch user doc
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.exists() ? userDoc.data() : {};

      const finishedLessons = userData.finishedLessons || [];
      const finishedWorkbooks = userData.finishedWorkbooks || [];

      // 4b. Fetch course doc (using user.courseID)
      if (user.courseID) {
        const courseDocRef = doc(db, 'courses', user.courseID);
        const courseDoc = await getDoc(courseDocRef);

        if (courseDoc.exists()) {
          const courseData = { id: courseDoc.id, ...courseDoc.data() };

          // 4c. Fetch modules
          const modulesSnapshot = await getDocs(collection(courseDocRef, 'modules'));
          const modulesData = await Promise.all(
            modulesSnapshot.docs.map(async (moduleDoc) => {
              const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };

              // 4d. Fetch lessons inside each module
              const lessonsSnapshot = await getDocs(
                collection(courseDocRef, 'modules', moduleDoc.id, 'lessons')
              );
              const lessonsData = await Promise.all(
                lessonsSnapshot.docs.map(async (lessonDoc) => {
                  const lessonData = {
                    id: lessonDoc.id,
                    moduleID: moduleDoc.id,
                    ...lessonDoc.data(),
                  };

                  // Fetch user interactions for the lesson
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'lessons',
                    lessonDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  if (interactionDoc.exists()) {
                    lessonData.userInteractions = interactionDoc.data();
                  } else {
                    lessonData.userInteractions = {};
                  }

                  return lessonData;
                })
              );

              // 4e. Fetch workbooks inside each module
              const workbooksSnapshot = await getDocs(
                collection(courseDocRef, 'modules', moduleDoc.id, 'workbooks')
              );
              const workbooksData = await Promise.all(
                workbooksSnapshot.docs.map(async (workbookDoc) => {
                  const workbookData = {
                    id: workbookDoc.id,
                    moduleID: moduleDoc.id,
                    ...workbookDoc.data(),
                  };

                  // Fetch user interactions for the workbook
                  const interactionRef = doc(
                    db,
                    'courses',
                    user.courseID,
                    'modules',
                    moduleDoc.id,
                    'workbooks',
                    workbookDoc.id,
                    'interactions',
                    user.uid
                  );
                  const interactionDoc = await getDoc(interactionRef);
                  if (interactionDoc.exists()) {
                    workbookData.userInteractions = interactionDoc.data();
                  } else {
                    workbookData.userInteractions = {};
                  }

                  return workbookData;
                })
              );

              // Return the module + its lessons & workbooks
              return {
                ...moduleData,
                lessons: lessonsData,
                workbooks: workbooksData,
              };
            })
          );

          const fullCourseData = {
            ...courseData,
            modules: modulesData,
          };

          setSelectedCourse(fullCourseData);

          // Flatten out the lessons/workbooks
          const allLessons = modulesData.flatMap((m) => m.lessons || []);
          const allWorkbooks = modulesData.flatMap((m) => m.workbooks || []);

          // Filter out finished
          const unfinishedLessons = allLessons.filter(
            (lesson) => !finishedLessons.some((f) => f.lessonID === lesson.id)
          );
          const unfinishedWorkbooks = allWorkbooks.filter(
            (workbook) => !finishedWorkbooks.some((f) => f.workbookID === workbook.id)
          );

          // Set next available
          setNextLesson(unfinishedLessons[0] || null);
          setNextWorkbook(unfinishedWorkbooks[0] || null);
        } else {
          setError('Selected course does not exist.');
        }
      }
    } catch (err) {
      console.error('Error fetching user or course data:', err);
      setError('Failed to fetch course data.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  // 4f. Trigger fetching user & course data once user is ready
  useEffect(() => {
    if (!authLoading && user) {
      fetchUserAndCourseData();
    } else {
      setLoading(false);
    }
  }, [user, authLoading, fetchUserAndCourseData]);

  // -----------------------------
  // 5. SAVE INTERACTIONS
  // -----------------------------
  const saveInteractionsImmediately = async () => {
    if (!user || !currentLessonOrWorkbookID) return;

    try {
      const [type, moduleID, contentID] = currentLessonOrWorkbookID.split('/');
      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        type === 'lesson' ? 'lessons' : 'workbooks',
        contentID,
        'interactions',
        user.uid
      );
      await setDoc(interactionRef, userInteractions, { merge: true });
    } catch (err) {
      console.error('Error saving user interactions:', err);
      setError('Failed to save interactions.');
    }
  };

  // Debounce to limit the number of writes
  useEffect(() => {
    const handler = setTimeout(() => {
      saveInteractionsImmediately();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  // Save interactions when the user closes the tab/browser
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Attempt to save right away
      saveInteractionsImmediately();
      // (Can't reliably await here, but it tries)
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userInteractions, user, currentLessonOrWorkbookID]);

  // Update interaction state in memory
  const updateUserInteraction = (key, interaction) => {
    setUserInteractions((prev) => ({
      ...prev,
      [key]: interaction,
    }));
  };

  // -------------------------------
  // 6. FINISH A LESSON
  // -------------------------------
  const finishLesson = async (lessonID, lessonStats = {}) => {
    try {
      if (!user || !lessonID) return;
      const userRef = doc(db, 'users', user.uid);
      const [moduleID, contentID] = lessonID.split('/');

      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'lessons',
        contentID,
        'interactions',
        user.uid
      );

      const batch = writeBatch(db);

      const newFinishedLesson = {
        lessonID: contentID,
        ...lessonStats,
        date: new Date().toISOString(),
      };

      // Mark lesson as finished in user doc
      batch.update(userRef, {
        finishedLessons: arrayUnion(newFinishedLesson),
        currentLessonID: lessonID,
      });

      // Save interactions
      batch.set(interactionRef, userInteractions, { merge: true });

      await batch.commit();
      fetchUserAndCourseData();
    } catch (err) {
      console.error('Error finishing lesson:', err);
      setError('Failed to finish the lesson.');
    }
  };

  // -------------------------------
  // 7. FINISH A WORKBOOK
  // -------------------------------
  const finishWorkbook = async (workbookID, workbookStats = {}) => {
    try {
      if (!user || !workbookID) return;
      const userRef = doc(db, 'users', user.uid);
      const [moduleID, contentID] = workbookID.split('/');

      const interactionRef = doc(
        db,
        'courses',
        user.courseID,
        'modules',
        moduleID,
        'workbooks',
        contentID,
        'interactions',
        user.uid
      );

      const batch = writeBatch(db);

      const newFinishedWorkbook = {
        workbookID: contentID,
        ...workbookStats,
        date: new Date().toISOString(),
      };

      // Mark workbook as finished in user doc
      batch.update(userRef, {
        finishedWorkbooks: arrayUnion(newFinishedWorkbook),
        currentWorkbookID: workbookID,
      });

      // Save interactions
      batch.set(interactionRef, userInteractions, { merge: true });

      await batch.commit();
      fetchUserAndCourseData();
    } catch (err) {
      console.error('Error finishing workbook:', err);
      setError('Failed to finish the workbook.');
    }
  };

  // ------------------------------------------
  // 8. PROVIDE EVERYTHING VIA CONTEXT
  // ------------------------------------------
  return (
    <LearningContext.Provider
      value={{
        // Course Data
        selectedCourse,
        nextLesson,
        nextWorkbook,

        // Finishing
        finishLesson,
        finishWorkbook,

        // Loading & Error
        loading,
        error,

        // Interactions
        userInteractions,
        updateUserInteraction,
        setUserInteractions,
        setCurrentLessonOrWorkbookID,

        // Test Results
        saveTestResult,
        fetchTestResults,
      }}
    >
      {!loading && children}
    </LearningContext.Provider>
  );
};
